import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import styles from './Dropzone.module.css'
import { useRecoilState, useSetRecoilState } from "recoil";
import { blocksListAtom, currentElementsAtom, showUploadWindowAtom } from "../recoil/atoms";
import CytoscapeComponent from "react-cytoscapejs";
import { saveBlock, stringifyToElements } from "../utils";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: '10px',
    borderColor: 'var(--secondary-color)',
    borderStyle: 'dashed',
    backgroundColor: 'var(--primary-color)',
    color: 'var(--secondary-color)',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    width: '90%'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};
/**
 * Use this component to handle file uploads
 * @param {Object} props
 * @param {cytoscape.Core} props.cyto - cytoscape core instance, consistent across app with Ref in App.js
 * @param {boolean} [props.student=false] - true if this is in the student version of the app
 * @returns 
 */
export default function Dropzone({cyto, student=false}) {
    const [currentElements, setCurrentElements] = useRecoilState(currentElementsAtom);
    const [showUploadWindow, setShowUploadWindow] = useRecoilState(showUploadWindowAtom);
    const [blocksList, setBlocksList] = useRecoilState(blocksListAtom)
    const [screen, setScreen] = useState('select')
    const [mode, setMode] = useState('replace')

    useEffect(()=>{
        student? setScreen('upload'): setScreen('select');
    }, [showUploadWindow])

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: { "application/json": ['.json'] },
        onDrop: acceptedFiles => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader()

                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = () => {
                    fetch(reader.result)
                        .then((response) => {
                            return response.json()
                        })
                        .then(body => {
                            console.log(mode)
                            if(mode==='replace'){
                                setCurrentElements(body)
                                setShowUploadWindow(false)
                                // we save the blocks in the spacetext file (JSON) to carry over, we have to see if present before parsing
                                let newBlockListNode = JSON.parse(body).nodes.filter(item => item.data.id === 'blocks')
                                console.log(newBlockListNode)
                                if(newBlockListNode.length > 0){
                                    let newBlockList = newBlockListNode[0].data.list
                                    console.log(newBlockList)
                                    console.log(blocksList)
                                    setBlocksList(newBlockList)
                                    console.log(blocksList)
                                }
                            }
                            else if(mode==='add'){
                                let elementsToBeAdded = CytoscapeComponent.normalizeElements(JSON.parse(body))
                                let addedElements = cyto.current.add(elementsToBeAdded);
                                saveBlock(addedElements, cyto.current, setBlocksList);
                                const data = cyto.current.json().elements;
                                setCurrentElements(
                                    stringifyToElements(data)
                                );
                                cyto.current.nodes().ungrabify()
                                setShowUploadWindow(false)
                            }
                            else{
                                throw new Error('not in a valid mode in Dropzone.js')
                            }
                        })
                }
                reader.readAsDataURL(file)
            })
        },
        maxFiles: 1
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    function getContainerDisplay(){
        if (showUploadWindow){
            return 'flex';
        }
        else{
            return 'none';
        }
    }

    return (
        <section className={styles.container} style={{display:getContainerDisplay()}}>
            {screen==='select'?
                <div className={styles.btns}>
                <button onClick={()=>{setScreen('upload'); setMode('add')}}>Add elements to existing landscape</button>
                <button onClick={()=>{setScreen('upload'); setMode('replace')}}>Replace current landscape</button>
                </div>:
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p style={{"text-align":"center"}}>Click here to select your space text file or drag/drop</p>
            </div>
            }        
        </section>
    );
}