import Navbar from '../Navbar/Navbar';
import style from '../App/App.module.css';
import Graph from '../Graph/Graph';
import ContentView from '../ContentView/ContentView';
//import SideNav from '../SideNav/SideNav'
import React, { useEffect, useRef } from 'react';
import Dropzone from '../Dropzone/Dropzone'
import { useSetRecoilState } from 'recoil';
import { currentElementsAtom, tagsListAtom } from '../recoil/atoms';

/**
 * Student App
 * @param {Object} props
 * @param {boolean} [props.bundled] - true if this is a particular class with bundled spacetext
 * @param {string} [props.spacetext] - bundled spacetext with app, should be stringified JSON in format downloaded from app
 * @returns 
 */
export default function StudentApp({bundled=false, spacetext=null}) {

    // everything here is the same as App except the student props are set true

    const ref = useRef();
    const edgehandlesRef = useRef();
    const contextMenuRef = useRef();
    const setCurrentElements = useSetRecoilState(currentElementsAtom);
    const setTagsList = useSetRecoilState(tagsListAtom)

    useEffect(()=>{
        if(spacetext){
            let newTagListNode;
            try {
                newTagListNode = JSON.parse(spacetext).nodes.filter(item => item.data.id === 'tags')
            } catch (error) {
                console.log('problem parsing new tags list')
            }
            if(newTagListNode.length > 0){
                let newTagList = newTagListNode[0].data.list
                setTagsList(newTagList)
            }
            else{
                setTagsList([])
            }
            setCurrentElements(spacetext)
        }
    }, [spacetext, ref])

    function setContextMenu(contextMenu){
        contextMenuRef.current = contextMenu;
    }
    
    function setEdgeHandles(edgehandles){
        edgehandlesRef.current = edgehandles
    }

    function setCyto(cyto){
        ref.current = cyto
    }

    return (
        <div className='page'>
            <Navbar cyto={ref} student bundled={bundled}/>
            <Dropzone cyto={ref} student/>
            {/*showUploadWindow ? <Dropzone/> : <></>*/}
            <div id={style.main}>
                <Graph cyto={ref} passUpRef={setCyto} edgehandles={edgehandlesRef} setEdgeHandles={setEdgeHandles} 
                contextMenu={contextMenuRef} setContextMenu={setContextMenu}/>
                {/*showContentView ? <ContentView cyto={ref} /> : <></>*/}
                <ContentView cyto={ref} student/>
            </div>
        </div>
    );
}
