import React, { useState } from "react";
import { Compass, Search, Bookmark, ClipboardList, History } from "lucide-react";
import style from './SideNav.module.css';
import nav_style from '../Navbar/Navbar.module.css'

export default function SideNav({cyto, student=false}){
    const [showSearch, setShowSearch] = useState(false)
    const [input, setInput] = useState('Node UID')

    function findNode(){
        let cleanId = input.split('-').join('');
        let node = cyto.current.$id(cleanId);
        if(node.length > 0){
            cyto.current.center(node)
            node.emit('dblclick')
            setShowSearch(false)
        }
        else{
            alert('No node found with that UID')
        }
    }

    return(
        <>
        <div id={style.sidenav}>
            {student?
            <button className={nav_style.btn} onClick={()=>setShowSearch(!showSearch)}><Search size={25} strokeWidth={1}/></button>:
            <>
            <button className={nav_style.btn}><Compass size={25} strokeWidth={1}/></button>
            <button className={nav_style.btn} onClick={()=>setShowSearch(!showSearch)}><Search size={25} strokeWidth={1}/></button>
            <button className={nav_style.btn}><Bookmark size={25} strokeWidth={1}/></button>
            <button className={nav_style.btn}><ClipboardList size={25} strokeWidth={1}/></button>
            <button className={nav_style.btn}><History size={25} strokeWidth={1}/></button>
            </>
            }
        </div>
        {showSearch? 
        <div className={style.search}>
            <textarea value={input} onChange={(e)=> {setInput(e.target.value);}}></textarea>
            <button onClick={findNode}>Search</button>
        </div>:
        <></>
        }
        </>
        
    )
}