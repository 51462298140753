import { useEffect, useState } from "react";
import ReactSlider from "react-slider";
import './Slider.css'
import { useRecoilValue } from "recoil";
import { currentNodeAtom, selectedElementsAtom } from "../../../recoil/atoms";

/**
 * Slider component to allow for node resizing in arrange mode
 * @param {Object} props
 * @param {cytoscape.Core} props.cyto - cytoscape core instance, consistent across app with Ref in App.js
 * @returns 
 */
export default function Slider({ cyto }){
  const [currentValue, setCurrentValue] = useState(50);
  const currentNode = useRecoilValue(currentNodeAtom);
  const [initialSize, setInitialSize]= useState();
  const selectedElements = useRecoilValue(selectedElementsAtom)

  useEffect(()=>{
    if(selectedElements.length === 0 && currentNode.id !== null && currentNode.id !== undefined){
      let currEle = cyto.$id(currentNode.id)
      let currWidth = Number.parseInt(currEle.style('width').split('px').join(''));
      let currHeight = Number.parseInt(currEle.style('height').split('px').join(''));
      let sizeObj = {id: currentNode.id, width: currWidth, height: currHeight}
      setInitialSize([{sizeObj}])
    }
  },[currentNode])

  useEffect(()=>{
    if(selectedElements.length > 0){
      setInitialSize([])
      selectedElements.forEach((e)=>{
        let currEle = cyto.$id(e)
        if(currEle.length === 0){return;}
        let currWidth = Number.parseInt(currEle.style('width').split('px').join(''));
        let currHeight = Number.parseInt(currEle.style('height').split('px').join(''));
        let sizeObj = {id: e, width: currWidth, height: currHeight}
        setInitialSize((old)=>{
          return [...old, sizeObj]
        })
      })
    }
  }, [selectedElements])

  useEffect(()=>{
    if(initialSize && initialSize.length > 1){
      initialSize.forEach((size)=>{
        /** @type {cytoscape.NodeSingular} */
        let currEle = cyto.$id(size.id);
        let modifier = currentValue/50;
        if(modifier===0){modifier=.1}
        currEle.style('width', size.width * modifier);
        currEle.style('height', size.height * modifier);

        //we're saving it in the data so we can use a selector in style.json of graph to present it with updated dimensions
        // even when the page reloads or spacetext is downloaded
        currEle.data('width', size.width * modifier);
        currEle.data('height', size.height * modifier);
        currEle.data('text_width', (size.height * modifier) - 15)
      })
    }
    else if(currentNode.id !== null && currentNode.id !== undefined && initialSize && initialSize.length === 1){
      /** @type {cytoscape.NodeSingular} */
      let currEle = cyto.$id(currentNode.id);
      let modifier = currentValue/50;
      if(modifier===0){modifier=.1}
      currEle.style('width', initialSize[0].sizeObj.width * modifier);
      currEle.style('height', initialSize[0].sizeObj.height * modifier);

      //we're saving it in the data so we can use a selector in style.json of graph to present it with updated dimensions
      // even when the page reloads or spacetext is downloaded
      currEle.data('width', initialSize[0].sizeObj.width * modifier);
      currEle.data('height', initialSize[0].sizeObj.height * modifier);
      currEle.data('text_width', (initialSize[0].sizeObj.height * modifier) - 15)
    }
  }, [currentValue, initialSize])

  return (
    <div className="customSlider">
        <ReactSlider
        thumbClassName="customSlider-thumb"
        trackClassName="customSlider-track"
        value={currentValue}
        onChange={(value) => {setCurrentValue(value)}}
        />
    </div>
  );
};
