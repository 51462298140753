import React, { useEffect, useRef, useState } from "react";
import style from './ArrangePopUp.module.css'
import createStar from "../../Graph/star/star";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { blocksListAtom, currentElementsAtom, currentNodeAtom, drawModeAtom, groupClickHandlerAtom, showSliderAtom, tagsListAtom } from "../../recoil/atoms";
import dropdownStyle from '../MoreDropdown/Dropdown.module.css'
import createPlanet from "../../Graph/planet/planet";
import createMoon from "../../Graph/moon/moon";
import createComet from "../../Graph/comet/comet";
import { saveBlock, stringifyToElements } from "../../utils";
import Slider from "./Slider/Slider";
import { saveTag } from "../../utils";

/**
 * Arrange mode, the tools necessary to manipulate the graph
 * @param {Object} props
 * @param {cytoscape.Core} props.cyto - cytoscape core instance, consistent across app with Ref in App.js
 * @param {import("cytoscape-edgehandles").EdgeHandlesInstance} props.edgehandles - edgehandles reference, we have to turn them off and on
 * @param {import("cytoscape-context-menus").ContextMenu} props.contextMenu - context menu reference, we have to turn them off and on
 * @returns 
 */
export default function ArrangePopUp({cyto, edgehandles, contextMenu}){
    const setCurrentElements = useSetRecoilState(currentElementsAtom);
    const currentElements = useRecoilValue(currentElementsAtom)
    const [deleteMode, setDeleteMode] = useState(false);
    const [drawMode, setDrawMode] = useRecoilState(drawModeAtom);
    const [groupMode, setGroupMode] = useState(false);
    const [blocksList, setBlocksList] = useRecoilState(blocksListAtom);
    const [tagsList, setTagsList] = useRecoilState(tagsListAtom);
    const [currentGroup, setCurrentGroup] = useState()
    const groupClickHandler = useRecoilValue(groupClickHandlerAtom);
    const setCurrentNode = useSetRecoilState(currentNodeAtom);
    const [deleteLock, setDeleteLock] = useState(false);
    const showSlider = useRecoilValue(showSliderAtom)
    // this useRef stuff is so that we can store a reference to the function that doesn't change
    // allows us to remove event listeners properly
    const deleteClickHandler = useRef(
        function deleteClickHandler(e){
            cyto.$(`#${e.target.data('id')}`).remove()
            cyto.nodes().removeListener('click', deleteClickHandler)
            cyto.edges().removeListener('click', deleteClickHandler);
            setDeleteMode(false)
        }
    )
    const lockedDeleteClickHandler = useRef(
        function lockedDeleteClickHandler(e){
            cyto.$(`#${e.target.data('id')}`).remove()
        }
    )

    useEffect(()=>{
        cyto.nodes().grabify()
        // context menu breaks mobile app so we dont do it on mobile
        if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))){
            contextMenu.current.showMenuItem('groups')
          }
    }, [])

    useEffect(()=>{
        setTimeout(()=>{
            if(deleteMode){
            cyto.on('click', deleteBackgroundClickHandler)
        }
        }, 150)
    }, [currentElements])

    useEffect(()=>{
        if(deleteMode){ 
            if(deleteLock){
                cyto.nodes().on('click', lockedDeleteClickHandler.current)
                cyto.edges().on('click', lockedDeleteClickHandler.current)
            }
            else{
                cyto.nodes().on('click', deleteClickHandler.current)
                cyto.edges().on('click', deleteClickHandler.current)
            }
            cyto.on('click', deleteBackgroundClickHandler)
            addEdgeHover()
        }
        else{ 
            // this emit thing works to get rid of some event listener, there's a better way to do it but same same
            cyto.emit('click', [true])
            cyto.edges().off('mouseover mouseout') 
        }
    }, [deleteMode, deleteLock])
    
    useEffect(()=>{
        if(!drawMode){
            edgehandles.current.disableDrawMode();
            cyto.nodes().grabify()
            console.log('grabby mitts')
        }
        else{
            edgehandles.current.enableDrawMode();
            cyto.nodes().ungrabify()
        }
    },[drawMode])
    
    useEffect(()=>{
        if(!showSlider){
            // save eles
            const data = cyto.json().elements;
            setCurrentElements(
                stringifyToElements(data)
            );
        }
    }, [showSlider])

    function addEdgeHover(){
        cyto.edges().on('mouseover', e =>{
            const targetNode = e.target
            const targetID = targetNode.data('id')
            const hoveredNodeCollection = cyto.$('#'+targetID)
            hoveredNodeCollection.toggleClass('active', true)
        });
        cyto.edges().on('mouseout', e =>{
            const targetNode = e.target
            const targetID = targetNode.data('id')
            const hoveredNodeCollection = cyto.$('#'+targetID)
            hoveredNodeCollection.toggleClass('active', false)
        })
    }
    /**
     * 
     * @param {String('tag' || 'block')} version 
     */
    function saveBtnClick(version){
        let selectedNodes = cyto.$('node:selected');
        let nodesInBlock = cyto.collection();
        selectedNodes.forEach(element => {
            if(!element.hasClass('invisible')){
                nodesInBlock = nodesInBlock.union(element);
            }
        });
        if(version === 'block'){
            saveBlock(nodesInBlock, cyto, setBlocksList);
        }
        else if(version === 'tag'){
            saveTag(nodesInBlock, cyto, setTagsList);
        }

        const data = cyto.json().elements;
        setCurrentElements(
            stringifyToElements(data)
        );
        setTimeout(()=>{
            if(drawMode){edgehandles.current.enableDrawMode()}
        }, 150)
    }

    function getExtent(){
        let { x1, y1, x2, y2, w, h } = cyto.extent()
        let xPos = (x2+x1)/2;
        let yPos = (y2+y1)/2;
        //console.log(cyto.extent())
        return{xPos, yPos}
    }

    function createNode(type){
        resetModes()
        let node;
        let postion = getExtent();
        //console.log(postion); 
        switch (type){
            case 'star': {
                node = createStar(cyto, 'graphic', 'keyphrase', 'title', 'summary', 'compressed', 'full');
                // we do this position thing so it makes the node in the middle of the screen
                node.position({x:postion.xPos, y:postion.yPos});  
                break;
            }
            case 'planet': {
                node = createPlanet(cyto, 'graphic', 'keyphrase', 'title', 'full'); 
                node.position({x:postion.xPos, y:postion.yPos}); 
                break;
            }
            case 'moon': {
                node = createMoon(cyto, 'graphic', 'keyphrase', 'title', 'full'); 
                node.position({x:postion.xPos, y:postion.yPos}); 
                break;
            }
            case 'comet' : {
                node = createComet(cyto, 'graphic', 'keyphrase', 'title', 'full'); 
                node.position({x:postion.xPos, y:postion.yPos}); 
                break;
            }
        }
    }

    

    function deleteModeToggle(){
        setDrawMode(false);
        setGroupMode(false)
        edgehandles.current.disableDrawMode();
        setDeleteMode(!deleteMode);
        deleteLock? setDeleteLock(false): setDeleteLock(deleteLock)
        /*if(deleteMode) {
            cyto.nodes().on('click', deleteClickHandler)
            cyto.on('click', deleteBackgroundClickHandler())
        }
        else{
            cyto.nodes().removeListener('click', deleteClickHandler)
            console.log(cyto.nodes())
            console.log('doufandno')
        }*/
    }

    function deleteBackgroundClickHandler(e) {
        if (e.target === cyto) {
            cyto.nodes().removeListener('click', deleteClickHandler.current);
            cyto.edges().removeListener('click', deleteClickHandler.current);
            cyto.nodes().removeListener('click', lockedDeleteClickHandler.current);
            cyto.edges().removeListener('click', lockedDeleteClickHandler.current);
            //cyto.removeListener('click', deleteBackgroundClickHandler);
            setDeleteMode(false);
            setDeleteLock(false)
        }
    }

    function getDeleteActive(){
        if(deleteMode){return 'var(--primary-hover)'}
        else{return 'var(--primary-color)'}
    }

    function toggleDrawMode(){
        setDeleteMode(false);
        setGroupMode(false)
        //console.log(!drawMode)
        setDrawMode(!drawMode)
    }

    function resetModes(){
        setDeleteLock(false)
        setDeleteMode(false);
        setDrawMode(false);
        setGroupMode(false)
        edgehandles.current.disableDrawMode();
    }

    function toggleGroupMode(){
        setDrawMode(false);
        setDeleteMode(false);
        setGroupMode(!groupMode);
        if(!groupMode)setCurrentNode({title:'ctrl + (drag/click) to select'})
    }

    function deleteModeLock(){
        setDeleteMode(true);
        setDeleteLock(true)
    }

    return (
        <>
        <div id={`${style.arrange_popup}`} className={`${dropdownStyle.dropdown}`}>
            <button className={dropdownStyle.dropdown_btn} onClick={()=>createNode('star')}>Add Star</button>
            <button className={dropdownStyle.dropdown_btn} onClick={()=>createNode('planet')}>Add Planet</button>
            <button className={dropdownStyle.dropdown_btn} onClick={()=>createNode('moon')}>Add Moon</button>
            <button className={dropdownStyle.dropdown_btn} onClick={()=>createNode('comet')}>Add Comet</button>
            <button className={dropdownStyle.dropdown_btn} onDoubleClick={deleteModeLock} style={{'background': getDeleteActive()}} onClick={deleteModeToggle}>Delete Element</button>
            <button className={dropdownStyle.dropdown_btn} style={drawMode? {'background': 'var(--primary-hover)'}: {'background':'var(--primary-color)'}} onClick={toggleDrawMode}>Add Edges</button>
            <button className={dropdownStyle.dropdown_btn} style={groupMode? {'background': 'var(--primary-hover)'}: {'background':'var(--primary-color)'}} onClick={toggleGroupMode}>Group Nodes</button>

            {groupMode? <button id={`${style.save}`} className={dropdownStyle.dropdown_btn} onClick={()=>saveBtnClick('block')}>Save Block</button>: <></>}
            {groupMode? <button id={`${style.save}`} className={dropdownStyle.dropdown_btn} onClick={()=>saveBtnClick('tag')}>Save Tag</button>: <></>}
        </div>
        {showSlider? <Slider cyto={cyto}/>: <></>}
        </>
    )
}