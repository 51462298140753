import React, { useEffect, useState } from "react";
import './Graph.css';
import '../style/colors.css'
import sty from './style.json'
import CytoscapeComponent from "react-cytoscapejs";
import './Graph.css'
import cytoscape from "cytoscape";
import { currentNodeAtom, showContentViewAtom, currentElementsAtom, arrangeModeAtom, showUploadWindowAtom, drawModeAtom, showIDBoxAtom, showSliderAtom, showArrangeToolsAtom, selectedElementsAtom } from "../recoil/atoms";
import { useRecoilState, useResetRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import cytoscapeEdgehandles from "cytoscape-edgehandles";
import { isValidEdge, stringifyToElements } from "../utils";
import cytoscapeLasso from 'cytoscape-lasso';
import contextMenus from 'cytoscape-context-menus';
import 'cytoscape-context-menus/cytoscape-context-menus.css';
import Modal from 'react-modal';

cytoscape.use(contextMenus);
cytoscape.use(cytoscapeLasso)
cytoscape.use(cytoscapeEdgehandles);

// {
//     "selector": ".dot",
//     "style": {
//       "height": "30px",
//       "width": "30px",
//       "shape": "ellipse",
//       "background-color": "rgb(139,135,135)",
//       "background-fill": "solid",
//       "background-opacity": "1",
//       "background-image": "none"
//     }
//   },

const ZOOMED_OUT_THRESHOLD = .8;
const ZOOMED_IN_THRESHOLD = 1.8;
const BODY_MODIFIER = 0;
let listeners =[]
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '20vw',
        height: '20vh',
        display: 'flex',
        flexDirection: 'column',
        alignElements: 'center',
        padding: '1% 3%'
    }
  };
// we have to have this so we can filter the defaults out when looking for blocks a node is in
const DEFAULT_CLASS_LIST = ['invisible', 'dot', 'comet', 'moon', 'planet', 'star', 'active', 'body']
/**
 * The core of the application - the graph, this component houses cytoscape and drives user interaction
 * @param {Object} props
 * @param {cytoscape.Core} props.cyto - cytoscape core instance, consistent across app with Ref in App.js
 * @param {Function} props.passUpRef - since we get the ref in the graph component we have to pass it up to App.js
 * @param {import("cytoscape-edgehandles").EdgeHandlesInstance} props.edgehandles - the edgehandles instance we store in App.js, library for edge creation
 * @param {Function} props.setEdgeHandles - again, passing up the ref to parent component because we get it in Graph
 * @param {Function} props.setContextMenu - passing up ref to parent component, promise this is the last one
 * @returns 
 */
export default function Graph({cyto, passUpRef, edgehandles, setEdgeHandles, setContextMenu}) {

    const setCurrentNode = useSetRecoilState(currentNodeAtom);
    const [selectedElements, setSelectedElements] = useRecoilState(selectedElementsAtom)
    const resetCurrentNode = useResetRecoilState(currentNodeAtom);
    const [ showContentView, setShowContentView ] = useRecoilState(showContentViewAtom);
    const [currentElements, setCurrentElements] = useRecoilState(currentElementsAtom);
    const arrangeMode = useRecoilValue(arrangeModeAtom);
    const resetShowUploadWindow = useResetRecoilState(showUploadWindowAtom)
    const [drawMode] = useRecoilState(drawModeAtom)
    const setShowIDBox = useSetRecoilState(showIDBoxAtom);
    const setShowSlider = useSetRecoilState(showSliderAtom);
    const setShowArrangeTools = useSetRecoilState(showArrangeToolsAtom)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [rightClickedNode, setRightClickedNode] = useState()

    useEffect(()=>{
        addChangeListeners(cyto.current, drawMode, setCurrentElements, edgehandles, arrangeMode, setShowSlider, setSelectedElements, selectedElements)
        cyto.current.autoungrabify(false);
        addNodeClickListener(cyto.current, focusNodeClick)
    }, [drawMode, arrangeMode])

    useEffect(() => {
        cyto.current.zoom(.7)
        cyto.current.center()

        let stars = cyto.current.collection('.star');
        addStarZoomListener(cyto.current, stars)

        let bodies = cyto.current.collection('.body');
        addBodyZoomListener(cyto.current, bodies)

        addDoubleClickListener(cyto.current, displayNewContent)
        addBackgroundClickListener(cyto.current, focusNodeClick, closeBtnClick)

        addNodeHoverListeners(cyto.current, focusNodeClick, closeBtnClick);
    }, []);

    useEffect(()=>{
        removeHoverListeners(cyto.current)
        addNodeClickListener(cyto.current, focusNodeClick);
        addDoubleClickListener(cyto.current, displayNewContent)
        if(!showContentView){   // since hover changes current node, we have to prevent that behavior when viewing node info
            addNodeHoverListeners(cyto.current, focusNodeClick, closeBtnClick);
            makeNodesInactive(cyto.current.nodes());
            closeBtnClick();
            cyto.current.nodes().unselect()
            setShowArrangeTools(true);
        }
        else{
            setShowSlider(false);
        }
    }, [showContentView])

    useEffect(()=>{
        cyto.current.autoungrabify(false)

        /** we have to redo all the listeners here because when the elements change
         * they don't get the listeners we added previously
         * specifically relevant for adding nodes
        */

        cyto.current.removeAllListeners()
        
        let stars = cyto.current.collection('.star');
        addStarZoomListener(cyto.current, stars)

        let bodies = cyto.current.collection('.body');
        addBodyZoomListener(cyto.current, bodies)

        addDoubleClickListener(cyto.current, displayNewContent)
        addBackgroundClickListener(cyto.current, focusNodeClick, closeBtnClick)

        addNodeHoverListeners(cyto.current, focusNodeClick, closeBtnClick);

        addNodeClickListener(cyto.current, focusNodeClick);

        setEdgeHandles(cyto.current.edgehandles({canConnect: isValidEdge}))
        // we have to see if we are on mobile because context menu is an old package and breaks on mobile
        if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))){
            setContextMenu(cyto.current.contextMenus({ menuItems: [
                    {
                id: 'groups', // ID of menu item
                content: 'groups', // Display content of menu item
                tooltipText: 'groups', // Tooltip text for menu item
                // Filters the elements to have this menu item on cxttap
                // If the selector is not truthy no elements will have this menu item on cxttap
                selector: 'node', 
                onClickFunction: function (event) { // The function to be executed on click
                    setRightClickedNode(event.target)
                    let blockList = event.target.classes().filter(value=>DEFAULT_CLASS_LIST.indexOf(value) === -1);
                    /**@type {Array} */
                    let niceBlockList = blockList.map((e)=>e.split('_').join(' '))
                    niceBlockList.length === 0? alert('This node is not in any groups'): setIsOpen(true)
                    // let blockList = event.target.classes().filter(value=>DEFAULT_CLASS_LIST.indexOf(value) === -1);
                    // /**@type {Array} */
                    // let niceBlockList = blockList.map((e)=>e.split('_').join(' '))
                    // let showGroupsGetGroup = function() {return niceBlockList.length === 0? alert('This node is not in any groups'): setIsOpen(true)} // prompt(`select block from this list:\n${niceBlockList.join('\n')}`)} //TODO change to modal
                    // let selectMe = showGroupsGetGroup()
                    // if(selectMe != "" && selectMe != undefined && niceBlockList.indexOf(selectMe) != -1){
                    //     let nodesToSelect = cyto.current.$(`.${selectMe.split(' ').join('_')}`)
                    //     nodesToSelect.select()
                    // }
                },
                disabled: false, // Whether the item will be created as disabled
                show: arrangeMode? true: false, // Whether the item will be shown or not
                hasTrailingDivider: false, // Whether the item will have a trailing divider
                coreAsWell: false // Whether core instance have this item on cxttap
                    }
                ]}
            ))
        }
        cyto.current.on('click', (e, arrange=false)=>{
            if(e.target === cyto.current && !arrange){
                resetShowUploadWindow();
            }
        })

        addChangeListeners(cyto.current, drawMode, setCurrentElements, edgehandles, arrangeMode, setShowSlider, setSelectedElements, selectedElements);
    }, [currentElements])

    function displayNewContent(keyphrase, title, text, compressedText, graphic, id, summary, image_1, image_2, image_3) {
        setCurrentNode({
            keyphrase: keyphrase,
            title: title,
            fullText: text,
            compressedText: compressedText,
            graphic: graphic,
            id: id,
            summary: summary,
            image_1: image_1,
            image_2: image_2,
            image_3: image_3
        });
        setShowContentView(true);
        setShowIDBox(true);
        if (arrangeMode && !showContentView) { setShowSlider(true); }
    }

    function closeBtnClick(hover=false) {
        setShowContentView(false);
        setShowIDBox(false);
        resetCurrentNode();
        if(!hover){
            setShowSlider(false);

        }
    }

    function focusNodeClick(keyphrase, title, text, compressedText, graphic, id, summary, hover=false, image_1, image_2, image_3){
        if(showContentView && !hover){
            displayNewContent(keyphrase, title, text, compressedText, graphic, id, summary, image_1, image_2, image_3);
        }
        else if(!hover && arrangeMode && !showContentView){
            setCurrentNode({
                keyphrase: keyphrase,
                title: title,
                fullText: text,
                compressedText: compressedText,
                graphic: graphic,
                id: id,
                summary: summary,
                image_1: image_1,
                image_2: image_2,
                image_3: image_3
            })
            setShowSlider(true);
        }
        else{
            setCurrentNode({
                keyphrase: keyphrase,
                title: title,
                fullText: text,
                compressedText: compressedText,
                graphic: graphic,
                id: id,
                summary: summary,
                image_1: image_1,
                image_2: image_2,
                image_3: image_3
            })
            setShowIDBox(true)
        }
    }

    let style = sty;    // change this later maybe to user setting

    function blockSelectClick(blockName){
        let nodesToSelect = cyto.current.$(`.${blockName.split(' ').join('_')}`)
        nodesToSelect.select()
        setIsOpen(false)
    }

    function getBlockButtons(){
        if(rightClickedNode !== undefined){
            let blockList = rightClickedNode.classes().filter(value=>DEFAULT_CLASS_LIST.indexOf(value) === -1);
        /**@type {Array} */
        let niceBlockList = blockList.map((e)=>e.split('_').join(' '))
        return niceBlockList.map((item, index) => 
            <button className="modalButton blockButton" onClick={()=>blockSelectClick(item.toString().split('_').join(' '))} key={index}>{item.toString().split('_').join(' ')}</button>)
        }
    }

    return (
        <>
            <CytoscapeComponent wheelSensitivity={.2} boxSelectionEnabled={false} id="cy" autoungrabify={false} cy={(cy) => { passUpRef(cy) }} elements={CytoscapeComponent.normalizeElements(JSON.parse(currentElements))} stylesheet={style} />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={()=>setIsOpen(false)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button onClick={()=>setIsOpen(false)} className="modalCloseButton modalButton">close</button>
                <div className="blocksDiv">
                    <p className="selectLabel">Select Block</p>
                    {getBlockButtons()}
                </div>
                
            </Modal>
        </>
        
    );
}

// ============================ LISTENERS ================================ //
// This section is seperate for some reason, I don't remember why - maybe the component was just bloated;
// some of it was probably because this is what I made first without knowing much JS/React

/**
 * Add the zoom listener for Star nodes, little bit different than other nodes so it is seperate
 * @param {cytoscape.Core} cy - cytoscape instance where collection is located
 * @param {cytoscape.CollectionReturnValue} coll - collection of Stars
 */
function addStarZoomListener(cy, coll) {
    cy.on('zoom', () => {  // could maybe add a call to cy.extent and check if node viewable before changing label
        let currentZoom = cy.zoom()
        if (currentZoom < ZOOMED_OUT_THRESHOLD) {   // zoomed out
            /*cy.style().selector('.star').style({
                'label': function (ele){return ele.data('keyphrase')},
                'background-image-opacity': 0,
                'text-opacity': 1,
                'font-size': '100%'
            }).update();    */
            coll.forEach((x) => {
                if(x.data('image_1') !== undefined){
                    x.style("background-image", `${x.data('image_1')}`)
                    x.style('background-offset-y', '0')
                }
                x.style('background-image-opacity', "1")
                x.style('text-opacity', "0")
                //x.toggleClass("dot", true)
            })
        }
        else if (currentZoom < ZOOMED_IN_THRESHOLD) {  // zoom medium
            coll.forEach((x) => {
                x.toggleClass("dot", false) //we have to do this for backwards compatibility
                if(x.data('image_2') !== undefined){
                    x.style("background-image", `${x.data('image_2')}`)
                    x.style('background-offset-y', '0')
                }
                else{
                    setLabelText(x, 'keyphrase');
                    setFontSize(x, '25%');
                    setLabelPosition(x, 'top')
                    showBackgroundAndLabel(x);
                    alignLabelAndImageInside(x);
                }
            })
        }
        else if (currentZoom >= ZOOMED_IN_THRESHOLD) { // zoom in
            coll.forEach((x) => {
                x.toggleClass("dot", false) //we have to do this for backwards compatibility
                if(x.data('image_3') !== undefined){
                    x.style("background-image", `${x.data('image_3')}`)
                    x.style('background-offset-y', '0')
                }
                else if(x.data('summary') !== undefined && x.data('summary') !== ''){
                    setLabelText(x, 'summary');
                    setFontSize(x, '10%');
                    setLabelPosition(x, 'center');
                    hideBackgroundShowLabel(x)
                }           
            })
        }
    });
}

/**
 * add the zoom listener for collection of non-star nodes
 * @param {cytoscape.Core} cy - cytoscape instance where nodes are
 * @param {cytoscape.CollectionReturnValue} coll - collection of nodes to add listener on
 */
function addBodyZoomListener(cy, coll) {
    cy.on('zoom', () => {  // could maybe add a call to cy.extent and check if node viewable before changing label
        let currentZoom = cy.zoom()
        if (currentZoom < (ZOOMED_IN_THRESHOLD - BODY_MODIFIER)) {   // zoomed out
            /*cy.style().selector('.star').style({
                'label': function (ele){return ele.data('keyphrase')},
                'background-image-opacity': 0,
                'text-opacity': 1,
                'font-size': '100%'
            }).update();    */
            coll.forEach((x) => {
                if(x.data('image_1') !== undefined){
                    x.style("background-image", `${x.data('image_1')}`)
                    x.style('background-offset-y', '0')
                }
                x.style('background-image-opacity', "1")
                x.style('text-opacity', "0")
                //x.toggleClass("dot", true)
            })
        }
        else if (currentZoom >= (ZOOMED_IN_THRESHOLD - BODY_MODIFIER)) { // zoom in
            coll.forEach((x) => {
                x.toggleClass("dot", false) // backwards compatibility
                if(x.data('image_2') !== undefined){
                    x.style("background-image", `${x.data('image_2')}`)
                    x.style('background-offset-y', '0')
                }
                else{
                    setLabelText(x, 'keyphrase');
                    setFontSize(x, '10%');
                    setLabelPosition(x, 'top')
                    showBackgroundAndLabel(x);
                    x.style('background-offset-y', '30px')
                    x.style('text-margin-y', '30px')
                }
            })
        }
    });
}

/**
 * 
 * @param {cytoscape.Core} cy 
 * @param {Function} populateSidebar - function to populate content view - should be "displayNewContent"
 */
function addDoubleClickListener(cy, populateSidebar) {
    cy.nodes().removeListener('dblclick')
    cy.nodes().on('dblclick', function (e) {
        /** @type {cytoscape.NodeSingular} */
        const clickedNode = e.target;
        const { keyphrase, title, text, compressed, graphic, id, summary, image_1, image_2, image_3 } = getNodeData(clickedNode);

        populateSidebar(keyphrase, title, text, compressed, graphic, id, summary, image_1, image_2, image_3)

        const targetID = clickedNode.data('id')
        const clickedNodeCollection = cy.$('#'+targetID)

        makeNodesInactive(cy.nodes())
        makeNodesActive(clickedNodeCollection)
        
        removeHoverListeners(cy);
    });
}

/**
 * 
 * @param {cytoscape.Core} cy 
 * @param {Function} focusNode - should be "focusNodeClick"
 */
function addNodeClickListener(cy, focusNode){
    listeners.forEach(l => {
        cy.nodes().removeListener('click', l)
    });
    cy.nodes().on('click', clickToFocusHandler)
    listeners.push(clickToFocusHandler)
    function clickToFocusHandler(e){
        //console.log(cy.nodes()[0]._private.emitter.listeners)
        const clickedNode = e.target;
        const { keyphrase, title, text, compressed, graphic, id, summary, image_1, image_2, image_3 } = getNodeData(clickedNode);

        focusNode(keyphrase, title, text, compressed, graphic, id, summary, false, image_1, image_2, image_3)
        
        const clickedCollection = cy.$('#'+id)

        makeNodesInactive(cy.nodes());
        makeNodesActive(clickedCollection);

        removeHoverListeners(cy);
    }
}

/**
 * mouseover and mouseout
 * @param {cytoscape.Core} cy 
 * @param {Function} focusNode - should be "focusNodeClick"
 * @param {Function} resetSidebar - should be "closeBtnClick" - gets rid of current node info
 */
function addNodeHoverListeners(cy, focusNode, resetSidebar) {
    cy.nodes().on('mouseover', function (e) {
        /** @type {cytoscape.NodeSingular} */
        const targetNode = e.target
        const { keyphrase, title, text, compressed, graphic, id, summary, image_1, image_2, image_3 } = getNodeData(targetNode);
        
        focusNode(keyphrase, title, text, compressed, graphic, id, summary, true, image_1, image_2, image_3)

        const clickedNodeCollection = cy.$('#'+id)

        makeNodesActive(clickedNodeCollection)

        //targetNode.select()
    })
    cy.nodes().on('mouseout', function (e){
         /** @type {cytoscape.NodeSingular} */
         const targetNode = e.target
         const targetID = targetNode.data('id')
         const clickedNodeCollection = cy.$('#'+targetID)

         resetSidebar(true)
 
         makeNodesInactive(clickedNodeCollection)
  
         //targetNode.unselect()
    })
}

/**
 * 
 * @param {cytoscape.Core} cy 
 * @param {Function} focusNode - need this to add the  hover listeners back
 * @param {Function} resetSidebar - this resets current node - should be "closeBtnClick"
 */
function addBackgroundClickListener(cy, focusNode, resetSidebar) {
    cy.on('click', function (e, arrange=false) {
        if (e.target === cy && !arrange) {
            resetSidebar()
            removeHoverListeners(cy)
            addNodeHoverListeners(cy, focusNode, resetSidebar)
            makeNodesInactive(cy.nodes());
        }
    })
}

/**
 * these listeners allow for auto save within arrange mode
 * @param {cytoscape.Core} cy 
 * @param {boolean} drawMode
 * @param {Function} setCurrentElements
 * @param {import("cytoscape-edgehandles").EdgeHandlesInstance} edgehandles
 */
function addChangeListeners(cy, drawMode, setCurrentElements, edgehandles, arrangeMode, setShowSlider, setSelectedElements, selectedElements){
    cy.off('ehcomplete add remove move free box boxstart unselect');
    cy.on('boxstart unselect', e=>{
        setSelectedElements([]);
    })
    cy.nodes().on('click', (e)=>{
        setSelectedElements((eles) => {
            return [...eles, e.target.data('id')]
        });
        setShowSlider(true)
    })
    cy.on('box', e =>{
        setShowSlider(true);
        setSelectedElements((eles) => {
            return [...eles, e.target.data('id')]
        });
    })
    cy.on('add remove move dragfree', (e)=>{    // drag free serves as moving a node, drag itself caused lag - same effect
        console.log(drawMode)
        if(drawMode){console.log('drawing');return;}
        if(!arrangeMode){console.log('not arranging'); return;}

        const data = cy.json().elements;
        setCurrentElements(
            stringifyToElements(data)
        );
    })
    cy.on('ehcomplete', (e)=>{  // this is for drawing edges, custom event of the extension
        if(!arrangeMode){console.log('not arranging'); return;}
        setTimeout(()=>{
            const data = cy.json().elements;
        setCurrentElements(
            stringifyToElements(data)
        );
        }, 150)
        setTimeout(()=>{
            if(drawMode){edgehandles.current.enableDrawMode()}
        }, 150)
    })
}
// =========================== HELPER FUNCTIONS =========================== //

function makeNodesInactive(collection) {
    collection.toggleClass('active', false);
}

function makeNodesActive(collection) {
    collection.toggleClass('active', true);
}

function removeHoverListeners(cy) {
    cy.nodes().removeListener('mouseout');
    cy.nodes().removeListener('mouseover');
}

function getNodeData(clickedNode) {
    const title = clickedNode.data('title');
    const text = clickedNode.data('fullText');
    const compressed = clickedNode.data('compressedText');
    const graphic = clickedNode.data('graphic');
    const id = clickedNode.data('id');
    const summary = clickedNode.data('summary');
    const keyphrase = clickedNode.data('keyphrase');
    const image_1 = clickedNode.data('image_1');
    const image_2 = clickedNode.data('image_2');
    const image_3 = clickedNode.data('image_3');
    return { keyphrase, title, text, compressed, graphic, id, summary, image_1, image_2, image_3 };
}

function setLabelText(node, newText) {
    node.style('label', function (ele) {
        return ele.data(newText);
    });
}

function setFontSize(ele, newSize) {
    ele.style('font-size', newSize);
}

function setLabelPosition(ele, position) {
    ele.style('text-halign', 'center');
    ele.style('text-valign', position);
    if(position == 'center'){
        ele.style('text-margin-y', '0%');
    }
}

function hideBackgroundShowLabel(ele) {
    ele.style('background-image-opacity', "0");
    ele.style('text-opacity', "1");
}

function showBackgroundAndLabel(x) {
    x.style('background-image-opacity', 1);
    x.style('text-opacity', 1);
}

function alignLabelAndImageInside(x) {
    x.style('background-offset-y', '20%');
    x.style('text-margin-y', '55%');
}
