import Navbar from '../Navbar/Navbar';
import style from './App.module.css';
import Graph from '../Graph/Graph';
import ContentView from '../ContentView/ContentView';
import React, { useRef } from 'react';
import Dropzone from '../Dropzone/Dropzone'

function App() {

    // we have to store these refs in app to pass them around to child components because of cytoscape
    const ref = useRef();
    const edgehandlesRef = useRef();
    const contextMenuRef = useRef();

    function setContextMenu(contextMenu){
        contextMenuRef.current = contextMenu;
    }
    
    function setEdgeHandles(edgehandles){
        edgehandlesRef.current = edgehandles
    }

    function setCyto(cyto){
        ref.current = cyto
    }

    return (
        <div className='page'>
            <Navbar cyto={ref} edgehandles={edgehandlesRef} contextMenu={contextMenuRef}/>
            <Dropzone cyto={ref}/>
            <div id={style.main}>
                <Graph cyto={ref} passUpRef={setCyto} edgehandles={edgehandlesRef} setEdgeHandles={setEdgeHandles} 
                contextMenu={contextMenuRef} setContextMenu={setContextMenu}/>
                <ContentView cyto={ref}/>
            </div>
        </div>
    );
}

export default App;
