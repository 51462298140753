import React from "react";
import Navbar from "../Navbar/Navbar";

export default function Settings(){
    return(
        <>
            <h1>Settings</h1>
        </>
    )
}
