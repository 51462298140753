// Import FirebaseAuth and firebase.
import React, { useRef, useState } from 'react';
import { auth } from '../firebase';
import style from './SignInScreen.module.css'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { BUILDER_UID, STUDENT_UID} from '../utils';
import { VECTOR_CALC_UID } from '../classProfiles/vectorCalc';
import { PROB_UID } from '../classProfiles/probability';
import { useNavigate } from 'react-router-dom';
import image from '../Navbar/fractal.jpg'
import '../style/colors.css'

{/*<div className={style.sign_in_container}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
  </div>*/}

/**
 * Login page, handles authentication
 * @returns 
 */
export default function SignInScreen() {
    const [data, setData] = useState({
        email: '',
        password: ''
    });
    const form = useRef();
    const navigate = useNavigate();

    function changeHandler(e) {
        setData({ ...data, [e.target.name]: [e.target.value] });
    }

    function submitHandler(e){
        e.preventDefault();
        signInWithEmailAndPassword(auth, data.email[0], data.password[0])
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                const uid = user.uid;
                if(uid == BUILDER_UID){
                    navigate('/builder', { replace: true });
                }
                else if(uid == STUDENT_UID){
                    navigate('/student', { replace: true });
                }
                else if(uid == VECTOR_CALC_UID){
                    navigate('/vector-calc', {replace: true});
                }
                else if(uid == PROB_UID){
                    console.log('prob')
                    navigate('/probability', {replace: true});
                }
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorCode)
                form.current.style.border = 'solid 5px red'
               // alert('error: ' + errorCode + ' ' + errorMessage + ' please try again')
            });
    }

    return (
        <div className={style.sign_in_container}>
            <img src={image} className={style.background}/>
            <form onSubmit={submitHandler} ref={form}>
                <label id={style.title}>Welcome to xTensiv</label>
                <label>
                    Email
                    <input type='text' name='email' value={data.email} onChange={changeHandler} />
                </label>
                <label>
                    Password
                    <input type='password' name='password' value={data.password} onChange={changeHandler} />
                </label>
                <input type='submit' name='submit' />
            </form>
        </div>
    );
}
