import React from "react";
import { Link } from "react-router-dom";
import style from './Dropdown.module.css'
import { useResetRecoilState } from "recoil";
import { currentNodeAtom } from "../../recoil/atoms";
import { signOut } from "@firebase/auth";
import { auth } from "../../firebase";

/**
 * Dropdown resulting from the dot dot dot in the navbar, accessing other pages
 * @param {Object} props
 * @param {boolean} [props.student=false] - true if this is in the student version of the app
 * @returns 
 */
export default function MoreDropdown({student=false}){

    const resetCurrentNode = useResetRecoilState(currentNodeAtom);
    
    function logOutClick(){
        signOut(auth).then(() => {
            console.log('success')
          }).catch((error) => {
            console.log(error)
          });
    }

    return(
        <div className={style.dropdown}>
            {student?
            <></>:
            <>
            <Link to='/builder/profile/notifications' onClick={resetCurrentNode}> <button className={style.dropdown_btn}>Notifications</button> </Link>
            <Link to='/builder/profile' onClick={resetCurrentNode}> <button className={style.dropdown_btn}>Account</button> </Link>
            <Link to='/builder/help' onClick={resetCurrentNode}> <button className={style.dropdown_btn}>Help</button> </Link>
            </>
            }
            <Link to='/login' onClick={resetCurrentNode}> <button onClick={logOutClick} className={style.dropdown_btn}>Logout</button> </Link>
        </div>
    )
}