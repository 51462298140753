import React from 'react'
import {useNavigate} from "react-router-dom"
/**@type {import('@firebase/auth').Auth} */
import { auth } from '../firebase';
import { onAuthStateChanged } from '@firebase/auth';

/**
 * Wrap the elements you want to protect in this in the route switch 
 * @param {Object} props 
 * @param {string} props.authorizedUID - UID that should have access to the element, defined in utils
 * @returns 
 */
export default function ProtectedRoute({children, authorizedUID}){
    const navigate = useNavigate();

    // we have to use onAuthStateChanged here so it listens for changes properly
    onAuthStateChanged(auth, (user) => {
        if (user) {
            const uid = user.uid;
            if (uid != authorizedUID) {
                navigate('/login', { replace: true });
            }
        } else {
            navigate('/login', { replace: true });
        }
    });

    return children
};

