import { atom } from "recoil";

// we use this to populate the content view, title bar, and id box, as well as getting node by id when selected
export const currentNodeAtom = atom({
    key: 'currentNodeAtom',
    default: {
        keyphrase: null,
        title: null,
        fullText: null,
        compressedText: null,
        summary: null,
        id: null,
        graphic: null,
        image_1: null,
        image_2: null,
        image_3: null
    }
});

export const showContentViewAtom = atom({
    key: 'showContentViewAtom',
    default: false
});

export const showSideNavAtom = atom({
    key: 'showSideNavAtom',
    default: false
})

export const showUploadWindowAtom = atom({
    key: 'showUploadWindowAtom',
    default: false
})

const localStorageEffect = key => ({setSelf, onSet}) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }
  
    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

// this is the "bundled spacetext"
export const currentElementsAtom = atom({
    key: 'currentElementsAtom',
    default: "{\"nodes\":[{\"data\":{\"id\":\"1\",\"graphic\":\"g\",\"keyphrase\":\"keyphrase1\",\"title\":\"title1\",\"summary\":\"summary1\",\"compressedText\":\"compressed1\",\"fullText\":\"full1\"},\"position\":{\"x\":239.5,\"y\":232.25},\"group\":\"nodes\",\"removed\":false,\"selected\":false,\"selectable\":true,\"locked\":false,\"grabbable\":true,\"pannable\":false,\"classes\":\"star\"},{\"data\":{\"id\":\"2\",\"graphic\":\"g\",\"keyphrase\":\"keyphrase comet\",\"title\":\"comet title\",\"fullText\":\"comet full text\"},\"position\":{\"x\":718.5,\"y\":232.25},\"group\":\"nodes\",\"removed\":false,\"selected\":false,\"selectable\":true,\"locked\":false,\"grabbable\":true,\"pannable\":false,\"classes\":\"comet body dot\"},{\"data\":{\"id\":\"3\",\"graphic\":\"g\",\"keyphrase\":\"moon keyphrase\",\"title\":\"moon title\",\"fullText\":\"moon full text\"},\"position\":{\"x\":239.5,\"y\":696.75},\"group\":\"nodes\",\"removed\":false,\"selected\":false,\"selectable\":true,\"locked\":false,\"grabbable\":true,\"pannable\":false,\"classes\":\"moon body dot\"},{\"data\":{\"id\":\"4\",\"graphic\":\"g\",\"keyphrase\":\"planet keyphrase\",\"title\":\"planet title\",\"fullText\":\"planet full text\"},\"position\":{\"x\":718.5,\"y\":696.75},\"group\":\"nodes\",\"removed\":false,\"selected\":false,\"selectable\":true,\"locked\":false,\"grabbable\":true,\"pannable\":false,\"classes\":\"planet body dot\"}]}",
    effects: [
        localStorageEffect('current_elements')
    ]

})

export const arrangeModeAtom = atom({
    key: 'arrangeModeAtom',
    default: false
})

//this gets populated from the blocks node
export const blocksListAtom = atom({
    key: 'blocksListAtom',
    default: [],
    effects:[
        localStorageEffect('blocks_list')
    ]
})

export const groupClickHandlerAtom = atom({
    key: 'groupClickHandlerAtom',
    default: (e)=>{
        alert('atom group click')
    }
})

export const showArrangeToolsAtom = atom({
    key: 'showArrangeToolsAtom',
    default: true
})

export const drawModeAtom = atom({
    key: 'drawModeAtom',
    default: false
})

export const showIDBoxAtom = atom({
    key: 'showIDBoxAtom',
    default: false
})

export const showSliderAtom = atom({
    key: 'showSliderAtom',
    default: false
})

export const selectedElementsAtom = atom({
    key: 'selectedElementsAtom',
    default: []
})

export const tagsListAtom = atom({
    key: 'tagsListAtom',
    default: [],
    effects:[
        localStorageEffect('tags_list')
    ]
})