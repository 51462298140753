/*
Contents-Graphic – thumbnail SVG (displays in Landscape)
Contents-Keyphrase – the 1-3 word short title (inline MJX to render) (displaysin Landscape)
Contents-Title – long (single line) title (inline MJX to render) (displays in allthree Headers: Landscape, RP, FSP)
Contents-Full – arbitrary length text (full MD & MJX to render) (displays inRP/FSP)
*/

import cytoscape from "cytoscape";
import {uniqueId} from "../../utils";

/**
 * 
 * @param {cytoscape.Core} cy 
 * cytoscape core instance to add comet to
 * @param {SVGImageElement} graphic 
 * @param {String} keyphrase 
 * 1-3 words that show up in the node
 * @param {String} title 
 * up to a line text descriping the node that displays in headers
 * @param {String} fullText 
 * @returns cytoscape node instance
 */
export default function createComet(cy, graphic, keyphrase, title, fullText){
    function Comet(id, graphic, keyphrase, title, fullText){ 
      this.data = {
        id: id,
        //"graphic": 'data:image/svg+xml;utf8,' + encodeURIComponent(graphic),
        "graphic": graphic,
        "keyphrase": keyphrase,
        "title": title,
        "fullText": fullText
      };
      this.classes = 
        [
            'comet',
            'body'
        ];
    }
    let uid = uniqueId()
    let temp = new Comet(uid, graphic, keyphrase, title, fullText)
    //console.log(temp.data.graphic)
    return cy.add(temp)
  }