import React, { useState, useEffect } from 'react'
import style from './ContentView.module.css'
import '../style/colors.css'
import { X, Expand, Minimize, BookX, BookPlus } from 'lucide-react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { showContentViewAtom, currentNodeAtom, currentElementsAtom, arrangeModeAtom, showArrangeToolsAtom } from '../recoil/atoms';
import ViewBox from './ViewBox/ViewBox';
import IDBox from './IDBox/IDBox';
import { stringifyToElements } from '../utils';

/**
 * Essentially a container for ViewBoxes that interfaces with the graph
 * @param {Object} props
 * @param {cytoscape.Core} props.cyto - cytoscape core instance, consistent across app with Ref in App.js
 * @param {boolean} [props.student=false] - true if this is in the student version of the app
 * @returns 
 */
export default function ContentView({cyto, student=false}) {

    const EXPANDED_SIZE = '90%';
    const MINIMIZED_SIZE = '33%';

    const [showContentView, setShowContentView] = useRecoilState(showContentViewAtom);
    const currentNode = useRecoilValue(currentNodeAtom);
    const setCurrentElements = useSetRecoilState(currentElementsAtom);
    const [editorCount, setEditorCount] = useState(2);
    const arrangeMode = useRecoilValue(arrangeModeAtom);
    const [size, setSize] = useState(MINIMIZED_SIZE);
    const setShowArrangeTools = useSetRecoilState(showArrangeToolsAtom)
    const [currentNodeIsStar, setCurrentNodeIsStar] = useState(false);
    
    useEffect(()=>{
        function getStartingSize(){
            if(arrangeMode){
                return EXPANDED_SIZE;
            }
            else{
                return MINIMIZED_SIZE;
            }
        }

        if(!showContentView) { setSize( getStartingSize() ); }
    }, [arrangeMode, showContentView])

    useEffect(()=>{
        if(showContentView && size === EXPANDED_SIZE){
            setShowArrangeTools(false)
        }
    }, [showContentView])

    useEffect(()=>{
        if(currentNode.id){
            let currentNodeObject = cyto.current.$id(currentNode.id);
            setCurrentNodeIsStar(currentNodeObject.hasClass('star'));
        }
    }, [currentNode])    

    function expandBtnClick() {
        if (size == MINIMIZED_SIZE) {
            setSize(EXPANDED_SIZE);
            setShowArrangeTools(false)
        }
        else if (size == EXPANDED_SIZE) {
            setSize(MINIMIZED_SIZE);
            setShowArrangeTools(true)
        }
    }

    function getExpandBtnIcon(){
        if (size == MINIMIZED_SIZE){
            return <Expand />
        }
        else if (size == EXPANDED_SIZE){
            return <Minimize />
        }
    }

    function closeBtnClick() {
        setShowContentView(false);
        setShowArrangeTools(true)
    }

    function pushBtnClick(){
        const currentNodeObject = cyto.current.getElementById(currentNode.id);
        currentNodeObject.data(currentNode)
        const data = cyto.current.json().elements;
        setCurrentElements(     //probably should pull this out into utils
            stringifyToElements(data)
        );
    }

    function getVisibility(){
        if (showContentView){
            return 'flex'
        }
        else{
            return 'none'
        }
    }

    function getTextShowing(){
        if (currentNode.summary != undefined && cyto.current.zoom() < 4 ) { return 'summary' }
        else { return (currentNode.compressedText != undefined? 'compressed text': 'full text')}
    }

    function addEditor(){
        if(editorCount < 2){
            setEditorCount(editorCount + 1);
        }
    }

    function removeEditor(){
        if(editorCount > 0){
            setEditorCount(editorCount - 1);
        }
    }

    function getDisplay(){
        if(size == EXPANDED_SIZE){
            return(
                <>
                    <div className={style.viewer} style={{ 'width': size, display: getVisibility() }}>
                        <div className={style.top}>
                            <div className={style.top_left}>
                                {editorCount > 0 && !student? <button className={`${style.cv_btn} ${style.remove}`} onClick={removeEditor}>  <BookX />  </button> : <></>}
                                {editorCount < 2 && !student ? <button className={`${style.cv_btn} ${style.add}`} onClick={addEditor}>  <BookPlus />  </button> : <></>}
                            </div>
                            <div style={{'height':'3.5vh'}} className={style.top_middle}>
                                {student? <h1 style={{'height':'6vh', 'fontSize':'4cqmin', 'marginBottom' : '1%'}} className={style.title}>{currentNode.keyphrase}</h1>: <></>}
                            </div>
                            <div className={style.top_right}>
                                <button className={`${style.expand} ${style.cv_btn}`} onClick={expandBtnClick}> {getExpandBtnIcon()} </button>
                                {student?
                                <></>:
                                <button className={`${style.push} ${style.cv_btn}`} onClick={pushBtnClick}>Push</button>
                                }
                                <button className={`${style.close} ${style.cv_btn}`} onClick={closeBtnClick}>  <X />  </button>
                            </div>
                        </div>
                        <div className={style.content}>
                            {editorCount >= 1? <ViewBox currentNodeIsStar={currentNodeIsStar} display={student} student={student} textShowing={currentNode.summary != undefined && currentNode.summary !== ''? 'summary': 'compressed text'}/>: <></>}
                            {editorCount === 2 && !student? <ViewBox currentNodeIsStar={currentNodeIsStar} display textShowing={currentNode.compressedText != undefined ? 'compressed text' : 'full text'}/>: <></>}
                            <ViewBox display={true} currentNodeIsStar={currentNodeIsStar} locked={student} textShowing={'full text'}/>
                        </div> 
                    </div>
                    {showContentView? <></> : <IDBox />}
                </>
            )
        }
        else{
            return(
                <>
                    <div className={style.viewer} style={{ 'width': size, display: getVisibility() }}>
                        <div className={style.small_top}>
                            {student && !currentNodeIsStar?
                            <></>:
                            <button className={`${style.expand} ${style.cv_btn}`} onClick={expandBtnClick}> {getExpandBtnIcon()} </button>}
                            <h1 className={style.title}>{currentNode.keyphrase}</h1>
                            <button className={`${style.close} ${style.cv_btn}`} onClick={closeBtnClick}>  <X />  </button>
                        </div>
                        {/*<p className={style.text}>{getText()}</p>*/}
                        <div className={style.content}><ViewBox display={true} rp locked={student && !currentNodeIsStar} student_rp={student} textShowing={getTextShowing()}/></div>
                        
                    </div>
                    <IDBox />
                </>
            )
        }
    }

    return (
        <>{getDisplay()}</>
    )

}