import React, { useState, useEffect } from 'react'
import style from './ViewBox.module.css'
import '../../style/colors.css'
import { useRecoilState } from 'recoil'
import { currentNodeAtom } from '../../recoil/atoms'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import remarkDirective from 'remark-directive'
import remarkCalloutDirectives from '@microflash/remark-callout-directives';
import rehypeRaw from 'rehype-raw';
import '@microflash/remark-callout-directives/themes/vitepress.css'
import 'github-markdown-css/github-markdown-light.css'

/**
 * Box containing text of a node, may be editable or display only, also has radio buttons
 * @param {Object} props
 * @param {boolean} props.display - true if this viewBox should be display only
 * @param {('full text' | 'keyphrase' | 'title' | 'compressed text' | 'summary')} [props.textShowing = 'full text'] - optional argument to specify default text
 * @param {boolean} [props.locked] - true if no option to change display
 * @param {boolean} [props.student] - true if in student app
 * @param {boolean} [props.student_rp] - true if in student app and right panel
 * @param {boolean} [props.rp]
 * @returns 
 */
export default function ViewBox({ rp=false, display, textShowing, locked=false, student=false, student_rp=false, currentNodeIsStar }){

    const [currentNode, setCurrentNode] = useRecoilState(currentNodeAtom);
    const [currentDisplay, setCurrentDisplay] = useState('full text')
    const [input, setInput] = useState(getCurrentText());

    useEffect(()=>{
        function getDefaultDisplay(){
            if (textShowing != undefined){
                return textShowing
            }
            else{
                return "full text"
            }
        }
    
        setCurrentDisplay(getDefaultDisplay());
    }, [textShowing])

    useEffect(()=>{
        setInput(getCurrentText())
    }, [currentDisplay, currentNode])


    function getCurrentText(){
        switch(currentDisplay){
            case "keyphrase": return currentNode.keyphrase;
            case "full text": return currentNode.fullText;
            case "title": return currentNode.title;
            case "compressed text": return currentNode.compressedText;
            case "summary": return currentNode.summary;
            case "image_1": return currentNode.image_1 || '';
            case "image_2": return currentNode.image_2 || '';
            case "image_3": return currentNode.image_3 || '';
            default: return 'error: invalid current display value';
        }
    }

    function hasCompressed(){
        return currentNode.compressedText != undefined;
    }

    function hasSummary(){
        return currentNode.summary != undefined;
    }

    function saveChanges(){
        switch(currentDisplay){
            case "full text": {
                setCurrentNode((oldNode) => {
                    const fullText = input;
                    return {...oldNode, fullText}
                });
                break;
            };
            case "title": {
                setCurrentNode((oldNode) => {
                    const title = input;
                    return {...oldNode, title}
                });
                break;
            };
            case "compressed text": {
                setCurrentNode((oldNode) => {
                    const compressedText = input;
                    return {...oldNode, compressedText}
                });
                break;
            };
            case "summary": {
                setCurrentNode((oldNode) => {
                    const summary = input;
                    return {...oldNode, summary}
                });
                break;
            };
            case "keyphrase": {
                setCurrentNode((oldNode) => {
                    const keyphrase = input;
                    return {...oldNode, keyphrase}
                });
                break;
            };
            case "image_1": {
                setCurrentNode((oldNode) => {
                    const image_1 = input;
                    return {...oldNode, image_1}
                });
                break;
            };
            case "image_2": {
                setCurrentNode((oldNode) => {
                    const image_2 = input;
                    return {...oldNode, image_2}
                });
                break;
            };
            case "image_3": {
                setCurrentNode((oldNode) => {
                    const image_3 = input;
                    return {...oldNode, image_3}
                });
                break;
            };
        }
    }

    function getClasses(selector){
        return `${style.button} ${currentDisplay == selector? style.clicked: ''}`
    }

    function switchDisplay(target){
        if(input != getCurrentText()){saveChanges();}
        setCurrentDisplay(target)
    }

    return(
        <div className={style.box}>
            {locked?
            <></>:
            <div className={style.buttons}>
                <div className={style.top_row}>
                    {student || student_rp || rp?
                    <></>:
                    <>
                    <button onClick={()=>switchDisplay('image_1')} className={getClasses('image_1')} style={{whiteSpace:'nowrap'}}>Image 1</button>
                    <button onClick={()=>switchDisplay('image_2')} className={getClasses('image_2')} style={{whiteSpace:'nowrap'}}>Image 2</button>
                    {currentNodeIsStar? <button onClick={()=>switchDisplay('image_3')} className={getClasses('image_3')} style={{whiteSpace:'nowrap'}}>Image 3</button>: <></>}
                    </>
                    }
                </div>
                <div className={style.bottom_row}>
                    {student || student_rp?
                    <></>:
                    <button onClick={()=>switchDisplay('keyphrase')} className={getClasses('keyphrase')}>Keyphrase</button>}
                    {student || student_rp?
                    <></>:
                    <button onClick={()=>switchDisplay('title')} className={getClasses('title')}>Title</button>}
                    {hasSummary() ? <button onClick={()=>switchDisplay('summary')} className={getClasses('summary')}>Summary</button> : <></> }
                    {hasCompressed() ? <button onClick={()=>switchDisplay('compressed text')} className={getClasses('compressed text')}>Compressed</button> : <></>}
                    {student?
                    <></>:
                    <button onClick={()=>switchDisplay('full text')} className={getClasses('full text')}>Full</button>}
                    {!display ?
                    <button onClick={saveChanges} id={style.commit} className={style.button} style={{'border': 'solid blue 1px'}}>Commit</button>:
                    <></>}
                </div>
            </div>}
            {display?
                <div className={`markdown-body ${style.content}`}><ReactMarkdown children={getCurrentText()} remarkPlugins={[remarkMath, remarkGfm, remarkDirective, remarkCalloutDirectives]} rehypePlugins={[rehypeRaw, rehypeKatex]}></ReactMarkdown></div>:
                <textarea className={style.content} value={input} onChange={(e)=> {setInput(e.target.value);}}/>}
        </div>
    )
}