import logo from './fractal.jpg';
import React, {useState, useEffect} from 'react';
import style from './Navbar.module.css';
import '../style/colors.css'
import { PanelLeftOpen, User, MoreVertical, Settings, Sparkles, PanelLeftClose, Download, Upload, Hand, Grab } from 'lucide-react';
import { Link } from 'react-router-dom';
import MoreDropdown from './MoreDropdown/MoreDropdown';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { arrangeModeAtom, currentElementsAtom, currentNodeAtom, showArrangeToolsAtom, showContentViewAtom, showSideNavAtom, showUploadWindowAtom } from '../recoil/atoms';
import SideNav from '../SideNav/SideNav';
import ArrangePopUp from './ArrangePopUp/ArrangePopUp';
import BlockDropdown from './BlockDropdown/BlockDropdown';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import remarkDirective from 'remark-directive';
import remarkCalloutDirectives from '@microflash/remark-callout-directives';
import 'github-markdown-css/github-markdown-light.css'
import '@microflash/remark-callout-directives/themes/vitepress.css'
import rehypeRaw from 'rehype-raw';

/**
 * Navbar you see at the top of every page, how we interact with parts of App like arrange mode
 * @param {Object} props
 * @param {cytoscape.Core} props.cyto - cytoscape core instance, consistent across app with Ref in App.js
 * @param {boolean} [props.student=false] - true if this is in the student version of the app
 * @param {import('cytoscape-edgehandles').EdgeHandlesInstance} props.edgehandles - we have to pass this through to arrange mode
 * @param {boolean} [props.basic] - if true, we get rid of all graph interaction options, essentially used on pages w/o graph
 * @param {import('cytoscape-context-menus').ContextMenu} contextMenu - we have to pass this through to arrange mode
 * @param {boolean} [props.bundled] - true if there should be no upload link
 * @returns 
 */
export default function Navbar({cyto, edgehandles, basic, contextMenu, student=false, bundled=false}) {

    const [showMoreDropdown, setShowMoreDropdown] = useState(false);
    const [showSideNav, setShowSideNav] = useRecoilState(showSideNavAtom)
    const currentNode = useRecoilValue(currentNodeAtom);
    const resetCurrentNode = useResetRecoilState(currentNodeAtom);
    const currentElements = useRecoilValue(currentElementsAtom);
    const [showUploadWindow, setShowUploadWindow] = useRecoilState(showUploadWindowAtom);
    const [arrangeMode, setArrangeMode] = useRecoilState(arrangeModeAtom);
    const resetShowContentView = useResetRecoilState(showContentViewAtom);
    const [showBlockDropdown, setShowBlockDropdown] = useState(false);
    const showArrangeTools = useRecoilValue(showArrangeToolsAtom);
    const resetShowArrangeTools = useResetRecoilState(showArrangeToolsAtom)

    useEffect(()=>{
      if(!basic){
        if(arrangeMode){
            cyto.current.nodes().grabify();
            cyto.current.lassoSelectionEnabled(true);
        }
        else{
            cyto.current.nodes().ungrabify();
            cyto.current.lassoSelectionEnabled(false);
        }
      }
    },[arrangeMode])

    useEffect(()=>{
      if(student){
        setShowSideNav(true)
      }
    }, [])

    const ICON_SIZE = 25;
    const STROKE_WIDTH = 1;

    function blockDropToggle() {
        setShowBlockDropdown(!showBlockDropdown);
        setShowMoreDropdown(false);
    }

    function expandMoreToggle(){
        setShowMoreDropdown(!showMoreDropdown);
        setArrangeMode(false);
        setShowBlockDropdown(false);
        if(!student){
          if(!basic){
          cyto.current.emit('click', [true])
        edgehandles.current.disableDrawMode();
        }
        }
    }

    function showSideNavToggle(){
        setShowSideNav(!showSideNav)
    }

    function resetGraph(){
        if(cyto){
            cyto.current.zoom(.7);
            cyto.current.center();
        }
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        // Create a blob with the data we want to download as a file
        const blob = new Blob([data], { type: fileType })
        // Create an anchor element and dispatch a click event on it
        // to trigger a download
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
      }
      
      const exportToJson = e => {
        e.preventDefault()
        //console.log(JSON.stringify(currentElements))
        downloadFile({
          data: JSON.stringify(currentElements),
          fileName: 'spaceText.json',
          fileType: 'text/json',
        })
      }

      function showUploadToggle(){
        setShowUploadWindow(!showUploadWindow);
        setArrangeMode(false)
      }

      function showArrangePopUpToggle(){
        setArrangeMode(!arrangeMode)
        // we have to test if on mobile because contextMenu breaks on mobile - it's old
        if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))){
          contextMenu.current.hideMenuItem('groups')
        }
        setShowMoreDropdown(false);
        cyto.current.emit('click', [true])
        edgehandles.current.disableDrawMode();
        setShowUploadWindow(false)
      }

      function resetState(){
        resetCurrentNode();
        setArrangeMode(false);
        setShowMoreDropdown(false);
        setShowUploadWindow(false);
        resetShowContentView();
        setShowBlockDropdown(false);
        resetShowArrangeTools();
      }

    return (
        <div className={style.nav_bar}>
            <div className={style.div}>
            {bundled? <Link id={style.logo_div} onClick={resetGraph}> <img src={logo} className={style.logo} alt='logo'></img> </Link>:
            student? <Link id={style.logo_div} to='/student' onClick={resetGraph}> <img src={logo} className={style.logo} alt='logo'></img> </Link>:
            <Link id={style.logo_div} to='/builder' onClick={resetGraph}> <img src={logo} className={style.logo} alt='logo'></img> </Link>
            }
            {student?
            <></>:
            <button onClick={showSideNavToggle} className={style.btn} id={style.open_panel}>
                {showSideNav ? <PanelLeftClose size={ICON_SIZE} strokeWidth={STROKE_WIDTH}/> : <PanelLeftOpen size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />}
                </button>
            }
            {showSideNav? <SideNav cyto={cyto} student={student}/> : <></>}
            {basic || student? <></>: <button onClick={showArrangePopUpToggle} className={style.btn}>{arrangeMode? <Grab size={ICON_SIZE} strokeWidth={STROKE_WIDTH}/>: <Hand size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />}</button>}
            {arrangeMode && !basic && !student && showArrangeTools? <ArrangePopUp contextMenu={contextMenu} cyto={cyto.current} edgehandles={edgehandles}/>: <></>}
            </div>

            <div id={style.current_title}><ReactMarkdown className={style.markdown} children={currentNode.title} remarkPlugins={[remarkMath, remarkGfm, remarkDirective, remarkCalloutDirectives]} rehypePlugins={[rehypeRaw ,rehypeKatex]}></ReactMarkdown></div>

          <div className={`${style.div} ${style.right}`}>
            {/*<a className={style.btn} download={'spaceText.json'} href={`data: ${data}`} id="exportJSON">*/}
            {basic || bundled? <></>: <button onClick={showUploadToggle} className={style.btn}><Upload size={ICON_SIZE} strokeWidth={STROKE_WIDTH} /></button>}
            {basic || student? <></>: <button onClick={exportToJson} className={style.btn}><Download size={ICON_SIZE} strokeWidth={STROKE_WIDTH} /></button>}
            {student?
            <></>:
            <Link to='/builder/profile'> <button onClick={resetState} className={style.btn}><User size={ICON_SIZE} strokeWidth={STROKE_WIDTH} /></button> </Link>
            }
            {basic? <></>: <button onClick={blockDropToggle} className={style.btn}><Sparkles size={ICON_SIZE} strokeWidth={STROKE_WIDTH} /></button>}
            {showBlockDropdown && !basic? <BlockDropdown student={student} cyto={cyto.current}/>: <></>}

            {student?
            <></>:
            <Link to='/builder/settings'> <button onClick={resetState} className={style.btn}><Settings size={ICON_SIZE} strokeWidth={STROKE_WIDTH} /></button> </Link>
            }
           
            <button onClick={expandMoreToggle} className={style.btn}><MoreVertical size={ICON_SIZE} strokeWidth={STROKE_WIDTH} /></button>
            {showMoreDropdown && !student ? <MoreDropdown /> : <></>}
            {showMoreDropdown && student ? <MoreDropdown student />: <></>}
          </div>
        </div>
    )
}