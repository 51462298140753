import React from "react";
import Navbar from "./Navbar/Navbar";
import { Outlet } from "react-router-dom";

export default function PageLayout(){
    
    return(
        <div className="page">
            <Navbar basic={true}/>
            <Outlet/>
        </div>
    )
}