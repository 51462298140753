/*
Contents-Graphic – thumbnail vector graphic
Contents-Keyphrase – the 1-3 word title (MJX to render)
Contents-Summary – a few bullet points text (MD & MJX to render)
Contents-Compressed – arbitrary length text (MD & MJX to render)
Contents-Full – arbitrary length text (MD & MJX to render)
*/

import cytoscape from "cytoscape";
import {uniqueId} from "../../utils";

/**
 * 
 * @param {cytoscape.Core} cy 
 * @param {Number} id 
 * @param {SVGImageElement} graphic 
 * @param {String} keyphrase 
 * @param {String} summary 
 * @param {String} compressedText 
 * @param {String} fullText 
 * @returns 
 */
export default function createStar(cy, graphic, keyphrase, title, summary, compressedText, fullText){
    function Star(id, graphic, keyphrase, title, summary, compressedText, fullText){ 
      this.data = {
        id: id,
        //"graphic": 'data:image/svg+xml;utf8,' + encodeURIComponent(graphic),
        "graphic": graphic,
        "keyphrase": keyphrase,
        "title": title,
        "summary": summary,
        "compressedText": compressedText,
        "fullText": fullText
      };
      this.classes = 
        [
            'star'
        ];
    }
    let uid = uniqueId()
    console.log(uid)
    let temp = new Star(uid, graphic, keyphrase, title, summary, compressedText, fullText)
    //console.log(temp.data.graphic)
    return cy.add(temp)
  }