import { useRecoilValue } from 'recoil'
import style from './IDBox.module.css'
import { currentNodeAtom, showIDBoxAtom } from '../../recoil/atoms'

/**
 * Little pop up box that displays a node's ID
 * @returns 
 */
export default function IDBox(){
    const showIDBox = useRecoilValue(showIDBoxAtom);
    const currentNode = useRecoilValue(currentNodeAtom);

    function getId(){
        if(currentNode.id === null || currentNode.id === undefined){
            return;
        }
        if(currentNode.id.length === 9){
            return `${currentNode.id.substr(0, 3)}-${currentNode.id.substr(3, 3)}-${currentNode.id.substr(6, 3)}`
        }
        else{
            return currentNode.id
        }
    }

    return(
        <>
        {showIDBox? 
            <div className={style.IDBox}>
                <p className={style.text}>{getId()}</p>
            </div> :
            <></>}
        </>
    )
}