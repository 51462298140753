import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import App from "./App/App"
import Profile from "./Profile/Profile"
import { RecoilRoot } from "recoil"
import Settings from "./Settings/Settings"
import PageLayout from "./PageLayout"
import SignInScreen from "./SignInScreen/SignInScreen"
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute"
import { BUILDER_UID, STUDENT_UID} from './utils';
import { PROB_UID, PROB_SPACETEXT } from "./classProfiles/probability";
import { VECTOR_CALC_SPACETEXT, VECTOR_CALC_UID } from "./classProfiles/vectorCalc"
import StudentApp from "./StudentApp/StudentApp"

const RouteSwitch = () => {
    // these paths are hardcoded elsewhere, be careful changing them
    return(
        <RecoilRoot>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<SignInScreen/>} />
                    <Route path="/builder" element={
                        <ProtectedRoute authorizedUID={BUILDER_UID}>
                        <App/>
                        </ProtectedRoute>
                    } />
                    <Route path='/builder' element={
                        <ProtectedRoute authorizedUID={BUILDER_UID}>
                        <PageLayout/>
                        </ProtectedRoute>
                    }>
                        <Route path='profile'>
                            <Route index element={<Profile />}/>
                            <Route path="notifications" element={<h1>Notifications</h1>}/>
                        </Route>
                        <Route path="settings" element={<Settings/>} />
                        <Route path="help" element={<h1>Help</h1>} />
                    </Route>
                    <Route path="/vector-calc" element={
                        <ProtectedRoute authorizedUID={VECTOR_CALC_UID}>
                            <StudentApp bundled spacetext={VECTOR_CALC_SPACETEXT} />
                        </ProtectedRoute>
                    } />
                    <Route path="/probability" element={
                        <ProtectedRoute authorizedUID={PROB_UID}>
                            <StudentApp bundled spacetext={PROB_SPACETEXT} />
                        </ProtectedRoute>
                    } />
                    {/**
                     * This is a template Route for new classes (versions of the App)
                     * Just fill in the fields in caps, UID and spacetext should be in utils
                     * 
                     * <Route path="/CLASS_NAME" element={
                        <ProtectedRoute authorizedUID={CLASS_UID}>
                            <StudentApp bundled spacetext={CLASS_SPACETEXT} />
                        </ProtectedRoute>
                    } />
                     */}
                    <Route path="/student" element={
                        <ProtectedRoute authorizedUID={STUDENT_UID}>
                        <StudentApp/>
                        </ProtectedRoute>
                    } />
                    <Route path="/login" element={<SignInScreen/>}/>
                </Routes>
            </BrowserRouter>
        </RecoilRoot>
    )
}

export default RouteSwitch;