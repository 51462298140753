import React, { useEffect } from "react";
import style from './BlockDropdown.module.css'
import dropdownStyle from '../MoreDropdown/Dropdown.module.css'
import { useRecoilValue } from "recoil";
import { blocksListAtom, tagsListAtom } from "../../recoil/atoms";

/**
 * Little block dropdown menu you access from the navbar
 * @param {Object} props
 * @param {cytoscape.Core} props.cyto - cytoscape core instance, consistent across app with Ref in App.js
 * @returns 
 */
export default function BlockDropdown({cyto, student}){ 
    const blocksList = useRecoilValue(blocksListAtom);
    const tagsList = useRecoilValue(tagsListAtom);
    /**let blocksListNames = blocksList.map((item)=>{
        <li key={Object.keys(item)[0]}>{Object.keys(item)[0]}</li>});

    useEffect(()=>{
        blocksList.forEach((i)=>console.log(Object.keys(i)[0]))
        blocksListNames = blocksList.map((item)=>{
            <li>{Object.keys(item)[0]}</li>
        })
    }, [blocksList])*/

    /**
     * 
     * @param {Event} event 
     */
    function checkBlock(event){
        let checkedCurrentValue = event.target.checked;
        let blockName = event.target.value.split(' ').join('_');
        let nodes = cyto.nodes()
        console.log('classes global')
        nodes.each((e)=>{console.log(e.classes()); console.log(e.data('id'))})
        console.log('-----------------------------')
        /**@type {cytoscape.Collection} */
        let coll = cyto.filter((e)=>{
            console.log('classes of node in filter')
            console.log(e.classes())
            return e.hasClass(blockName)
        })
        console.log('retrieving nodes with class: ')
        console.log(blockName)
        coll.each((e)=>{
            console.log(e.classes())
        })
        if(checkedCurrentValue){
            coll.toggleClass('invisible', false)
        }
        else{
            coll.toggleClass('invisible', true)
        }
    }

    /** 
    * @param {Event} event 
    */
   function checkTag(event){
       let checkedCurrentValue = event.target.checked;
       let tagName = event.target.value.split(' ').join('_');
       let nodes = cyto.nodes()
       /**@type {cytoscape.Collection} */
       let coll = cyto.filter((/**@type {cytoscape.NodeSingular}*/e)=>{
           return (e.data('tags') !== undefined && e.data('tags').includes(tagName))
       })
       if(checkedCurrentValue){
           coll.toggleClass('faded', false)
       }
       else{
           coll.toggleClass('faded', true)
       }
   }

    console.log(blocksList)

    /** TODO
     * extract each checklist item into a seperate component
     * each component will have it's own state to remember if checked
     * each component also has little hand "select" button on right to select that group
     * may have to make pop-up bigger
     * think about how to do select-all function
     */

    return(
        <> 
        <div className={`${dropdownStyle.dropdown}`} id={style.block_dropdown}>
            {student?
            <></>:
            <>
            <span>Blocks</span>
            {blocksList.length > 0 ?
                <ul>
                    {blocksList.map((item, index) => <li className={style.block} key={index}>
                        <input type="checkbox" id={index} defaultChecked onChange={checkBlock} value={item.toString().split('_').join(' ')}/>
                        <label htmlFor={index}>{item.toString().split('_').join(' ')}</label>
                    </li>)}
                </ul>
                : <ul><li><strong>No Items</strong></li></ul>
            }
            </>
            }
            <span>Tags</span>
            {tagsList.length > 0 ?
                <ul>
                    {tagsList.map((item, index) => <li className={style.block} key={index}>
                        <input type="checkbox" id={index} defaultChecked onChange={checkTag} value={item.toString().split('_').join(' ')}/>
                        <label htmlFor={index}>{item.toString().split('_').join(' ')}</label>
                    </li>)}
                </ul>
                : <ul><li><strong>No Items</strong></li></ul>
            }
        </div>
        
        </>
    )
}


 