import cytoscape from "cytoscape";
import ShortUniqueId from "short-unique-id";

export function uniqueId(){
  const uid = new ShortUniqueId({ 
    length: 8, 
    dictionary: "alphanum_upper" 
  });
  let generatedUid = uid()
  //const dateString = Date.now().toString(36);
  //const randomness = Math.random().toString(36).substr(2);
  return '0' + generatedUid; //format is 0XX-XXX-XXX
};

/**
 * we use this in the edgehandles extension
 * @param {cytoscape.NodeSingular} source 
 * @param {cytoscape.NodeSingular} target
 */
export function isValidEdge(source, target){
    if(source.same(target)){ // no edges to self
      return false;
    }
    //star
    if(target.hasClass('star') && !source.hasClass('star')){ //can't go to star from non-star
      return false;
    }
    //planet
    if(source.hasClass('planet') && !(target.hasClass('moon') || target.hasClass('comet'))){
      return false; // planet can only go to moon or comet
    }
    if(target.hasClass('planet') && (!source.hasClass('star') || target.incomers().length > 0)){
      return false; // only star can connect to planet, only can have one incoming connection
    }
    //moon
    if(source.hasClass('moon')){
      return false; //moon can't have outgoing arrows
    }
    if(target.hasClass('moon') && (!(source.hasClass('star') || source.hasClass('planet')) || target.incomers().length > 0)){
      return false; // moon can only have one incoming edge, from either a planet or star
    }
    //comet
    if(source.hasClass('comet')){
      return false; //no edges from comets
    }
    return true;
}

/**
 * Use this function to save a new block
 * @param {cytoscape.Collection} newBlock collection of elements in the new block
 * @param {cytoscape.Core} cyto cytoscape core instance (used to query for blocks node)
 * @param {import("react").SetStateAction} setBlocksList function to set state of the blocks list, should be for recoil atom
 */
export function saveBlock(newBlock, cyto, setBlocksList) {
  /**@type {cytoscape.Collection} */
  if (newBlock.nonempty()) {
      let blockIds = newBlock.map((ele) => { return ele.data('id'); });
      let blockName = prompt('name for your new block? (cancel saves graph - does not save block)');
      /**
       * clean/validate input
       * make sure not malicious, make sure doesn't match existing name
       */
      if (blockName != null && blockName != "") {
          let cleanName = blockName.split(' ').join('_');
          setBlocksList((oldList) => {
              //return [...oldList, { [cleanName]: blockIds }]; this is if we want to store ids in blockList, we don't need to do that
              return [...oldList, cleanName];
          });
          console.log(`new block name: ${cleanName}`);
          console.log('these are the ids of the nodes selected: ');
          console.log(blockIds);
          console.log('these are the selected node objects: ');
          console.log(newBlock);
          /** @type {cytoscape.Collection} */
          newBlock.addClass(cleanName);
          console.log('classes of each node (should have new one)');
          newBlock.each((e) => {
              console.log(e.classes());
          });
          let blockHolder = cyto.$id('blocks');
          // we store the blocks in an invisible node in the spacetext to save it
          // this just checks if it already exists
          if(blockHolder.nonempty()){
              let currList = blockHolder.data('list')
              currList.push(cleanName)
              blockHolder.data('list', currList)
              blockHolder.ungrabify();
              blockHolder.unselectify();
              blockHolder.panify();
              //console.log(blockHolder.data())
          }
          else{
              cyto.add({data: {id: 'blocks', list: [cleanName]}, classes: ['invisible'], selectable: false, grabbable: false, pannable: true})
              //console.log(cyto.$id('blocks'))
          }
      }
  }
}

/**
 * Use this function to save a new tag
 * @param {cytoscape.Collection} newTag collection of elements in the new tag
 * @param {cytoscape.Core} cyto cytoscape core instance (used to query for tag node)
 * @param {import("react").SetStateAction} setTagsList function to set state of the tags list, should be for recoil atom
 */
export function saveTag(newTag, cyto, setTagsList) {
  /**@type {cytoscape.Collection} */
  if (newTag.nonempty()) {
      let tagIds = newTag.map((ele) => { return ele.data('id'); });
      let tagName = prompt('name for your new tag? (cancel saves graph - does not save tag)');
      /**
       * clean/validate input
       * make sure not malicious, make sure doesn't match existing name
       */
      if (tagName != null && tagName != "") {
          let cleanName = tagName.split(' ').join('_');
          setTagsList((oldList) => {
              //return [...oldList, { [cleanName]: blockIds }]; this is if we want to store ids in blockList, we don't need to do that
              return [...oldList, cleanName];
          });
          /** @type {cytoscape.Collection} */
          newTag.forEach((e)=>{
            let elementTagList = e.data('tags');
            if(elementTagList === undefined){e.data('tags', [cleanName])}
            else{
              elementTagList.push(cleanName);
              e.data('tags', elementTagList)
            }
          })
          let tagHolder = cyto.$id('tags');
          if(tagHolder.nonempty()){
              let currList = tagHolder.data('list')
              currList.push(cleanName)
              tagHolder.data('list', currList)
              tagHolder.ungrabify();
              tagHolder.unselectify();
              tagHolder.panify();
              //console.log(blockHolder.data())
          }
          else{
              cyto.add({data: {id: 'tags', list: [cleanName]}, classes: ['invisible'], selectable: false, grabbable: false, pannable: true})
              //console.log(cyto.$id('blocks'))
          }
      }
  }
}

/**
 * Use this when setting current elements in state
 * @param {JSON} data - JSON of elements to be stringified
 * @returns stringified JSON with escaped backslashes
 */
export function stringifyToElements(data) {
  return JSON.stringify(
    data,
    (key, val) => {
    if (typeof val === 'string') {
        // return val.replace(/\\\\/i, '\\\\\\');
    }
    return val;
    }
)
}
const BUILDER_UID = 'KBPt9sJhvCObJj9YMqqBtcy1Wdo2'
const STUDENT_UID = 'UakQ5FAnaXQo7g0w7fT2ByCCYMI2'
export {BUILDER_UID, STUDENT_UID};